<template>
    <div class="marked-icon">
        <div v-if="marked" class="marked-icon_circle"></div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'MarkedIcon',
    components: {},
    props: {
        marked: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped lang="scss">
.marked-icon {
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
}
.marked-icon_circle {
    position: absolute;
    right: -15%;
    top: -15%;
    width: 40%;
    height: 0;
    padding-bottom: 40%; // using padding-bottom because the percent are in relation to the WIDTH of the container
    background-color: $color-accent;
    border-radius: 50%;
}
</style>
