<template>
    <ListItem :key="asset.id" class="u-bb no-padding">
        <router-link
            :to="assetLink"
            class="l-padded l-inline l-spread l-full-width l-gap-0"
        >
            <div class="l-inline l-gap-1">
                <asset-avatar :tracker="asset" :size="42" />

                <div class="l-stack l-gap-0">
                    <!-- Name -->
                    <h3>{{ asset.asset_details.name }}</h3>

                    <!-- Asset identification -->
                    <div
                        v-if="asset.asset_details.identification"
                        class="t-small"
                    >
                        {{ asset.asset_details.identification }}
                    </div>

                    <!-- Location -->
                    <div
                        v-if="asset.location_details != undefined"
                        class="t-small"
                    >
                        <pin-location-icon width="12" height="12" />
                        {{ asset.location_details.name }}
                    </div>

                    <!-- Tags -->
                    <div class="l-inline tags">
                        <div
                            v-for="(tag, index) in asset.asset_details.tags"
                            :key="index"
                            :class="tag.className"
                        >
                            {{ $t(tag.label) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="l-stack  t-subtle l-end  t-small">
                <div v-if="asset.last_message_timestamp" class="no-wrap">
                    <time-ago :from-datetime="asset.last_message_timestamp" />
                </div>

                <div v-if="batteryStatus" class="l-inline l-center l-gap-1">
                    <div class="no-wrap">{{ batteryStatus.value }} %</div>

                    <battery-icon
                        width="24"
                        height="24"
                        :battery-level-in-percent="batteryStatus.value"
                        :warn="batteryStatus.low"
                    />
                </div>
            </div>
        </router-link>
    </ListItem>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import ListItem from './ListItem'
import PinLocationIcon from './icons/PinLocationIcon'
import TimeAgo from './TimeAgo'
import AssetAvatar from '@/components/AssetAvatar'

export default {
    name: 'AssetListItem',
    components: {
        AssetAvatar,
        BatteryIcon,
        ListItem,
        PinLocationIcon,
        TimeAgo,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        assetDetailLinkBase: {
            type: String,
            default: '',
        },
    },
    computed: {
        assetLink() {
            const base = this.assetDetailLinkBase || this.$route.path
            return base + '/' + this.asset.id
        },
        batteryStatus() {
            return this.asset.asset_details.sensor_data?.battery
        },
    },
}
</script>

<i18n>
{
    "en": {
        "beaconPosition": "Beacon Position",
        "inactive": "INACTIVE",
        "leftZone": "LEFT ZONE"
    },
    "de": {
        "beaconPosition": "Beacon Position",
        "inactive": "INAKTIV",
        "leftZone": "ZONE VERLASSEN"
    },
    "it": {
        "beaconPosition": "Beacon Posizione",
        "inactive": "INATTIVO",
        "leftZone": "ESCONO ZONA"
    }
}
</i18n>

<style lang="scss" scoped>
.no-padding {
    padding: 0;
}

.no-wrap {
    white-space: nowrap;
}

.tags {
    flex-wrap: wrap;

    & > div {
        margin: 0 4px 4px 0;
        white-space: nowrap;
    }
}
</style>
