<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :viewbox-height="140"
        :viewbox-width="140"
        :color="color"
        @click="$emit('click')"
    >
        <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
            <path
                d="M0.499 12.001 A11.500 11.500 0 1 0 23.499 12.001 A11.500 11.500 0 1 0 0.499 12.001 Z"
                fill="none"
                stroke="#000000"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
            <path
                d="M12 6.501L12 12.001 18 17.501"
                fill="none"
                stroke="#000000"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></path>
        </g>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'RecentIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>
