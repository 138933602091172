<template>
    <div>
        <div class="l-padded u-bb">
            <SearchField v-model="search" />

            <div
                class="l-inline l-gap-1 t-small adjusting-mode-switch"
                @click="switchAdjustingMode"
            >
                <input
                    type="checkbox"
                    :checked="shouldAdjustMapOnFilterChange"
                />

                <p>{{ $t('adjustMapOnFilterChange') }}</p>
            </div>
        </div>

        <ListHeader class="u-bb">
            <div slot="title">
                <span v-if="listHeaderState == listHeaderConsts.none">
                    {{ $t('listedOf', [filteredAssets.length, assets.length]) }}
                </span>

                <span v-if="listHeaderState == listHeaderConsts.sort">
                    {{ $t('sortedBy') }}
                </span>

                <span v-if="listHeaderState == listHeaderConsts.filter">
                    {{ $t('filterBy') }}
                </span>
            </div>

            <div slot="accessory" class="l-inline l-gap-2">
                <icon-button
                    v-if="listHeaderState == listHeaderConsts.none"
                    @click="listHeaderState = listHeaderConsts.sort"
                >
                    <sort-icon color="black" width="18" height="18" />
                </icon-button>

                <icon-button
                    v-if="listHeaderState == listHeaderConsts.none"
                    @click="listHeaderState = listHeaderConsts.filter"
                >
                    <marked-icon :marked="isFiltered">
                        <filter-icon width="18" height="18" />
                    </marked-icon>
                </icon-button>

                <icon-button
                    v-if="listHeaderState != listHeaderConsts.none"
                    @click="listHeaderState = listHeaderConsts.none"
                >
                    <remove-icon width="18" height="18" />
                </icon-button>
            </div>

            <div slot="content">
                <transition name="dropdown">
                    <div
                        v-if="listHeaderState == listHeaderConsts.sort"
                        class="sort-panel l-stack l-padded l-gap-1 dropdown"
                    >
                        <div
                            v-for="(item, index, key) in sortData"
                            :key="key"
                            class="l-inline l-center-v l-gap-2 sort-option"
                            @click="sortBy = index + 1"
                        >
                            <input
                                type="radio"
                                :checked="sortBy == index + 1"
                            />
                            <div>{{ item.text }}</div>
                        </div>
                    </div>

                    <div
                        v-if="listHeaderState == listHeaderConsts.filter"
                        class="sort-panel l-stack l-padded l-gap-1 dropdown"
                    >
                        <div
                            class="l-inline l-gap-2 l-center-v sort-option"
                            @click="filterByBattery = !filterByBattery"
                        >
                            <input type="checkbox" :checked="filterByBattery" />

                            <battery-icon
                                width="18"
                                height="18"
                                battery-level-in-percent="10"
                                :warn="true"
                            />

                            <p>{{ $t('lowBattery') }}</p>
                        </div>

                        <div
                            class="l-inline l-gap-2 l-center-v sort-option"
                            @click="filterByLeftZone = !filterByLeftZone"
                        >
                            <input
                                type="checkbox"
                                :checked="filterByLeftZone"
                            />

                            <cursor-move-target-right-icon
                                width="18"
                                height="18"
                            />

                            <p>{{ $t('leftZone') }}</p>
                        </div>

                        <div
                            class="l-inline l-gap-2 l-center-v sort-option"
                            @click="filterByInactive = !filterByInactive"
                        >
                            <input
                                type="checkbox"
                                :checked="filterByInactive"
                            />

                            <disabled-icon width="18" height="18" />

                            <p>{{ $t('inactive') }}</p>
                        </div>

                        <div
                            class="l-inline l-gap-2 l-center-v sort-option"
                            @click="filterByAlert = !filterByAlert"
                        >
                            <input type="checkbox" :checked="filterByAlert" />

                            <warning-icon width="18" height="18" />

                            <p>{{ $t('hasAlert') }}</p>
                        </div>

                        <div class="l-inline l-center-v l-gap-1">
                            <div class="l-inline l-gap-2 sort-option">
                                <div
                                    class="l-inline l-gap-2"
                                    @click="
                                        filterByRecentlyActive = !filterByRecentlyActive
                                    "
                                >
                                    <input
                                        type="checkbox"
                                        :checked="filterByRecentlyActive"
                                    />

                                    <recent-icon width="18" height="18" />
                                </div>

                                <div class="l-stack l-gap-1">
                                    <p
                                        @click="
                                            filterByRecentlyActive = !filterByRecentlyActive
                                        "
                                    >
                                        {{ $t('recentlyActive') }}
                                    </p>

                                    <Multiselect
                                        v-show="filterByRecentlyActive"
                                        v-model="lastActiveFor"
                                        class="multiselect-redesigned"
                                        :options="lastActiveOptions"
                                        :custom-label="
                                            option => $t(option.duration)
                                        "
                                        :searchable="false"
                                        :show-labels="false"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="split">
                            <div class="u-bb"></div>
                        </div>

                        <AssetTypeSelect
                            v-model="filterByType"
                            :placeholder="$t('type')"
                            :multiple="true"
                        />

                        <LocationSelect
                            v-model="filterByLocation"
                            :placeholder="$t('location')"
                            :multiple="true"
                        />

                        <ColorInput v-model="filterByColor" />
                    </div>
                </transition>
            </div>
        </ListHeader>

        <List v-infinite-scroll="loadMoreAssets" infinite-scroll-distance="15">
            <AssetListItem
                v-for="asset in assetsPortion"
                :key="`AssetListItem${asset.id}`"
                :asset="asset"
                :asset-detail-link-base="assetDetailLinkBase"
            />
        </List>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import debounce from 'lodash.debounce'
import infiniteScroll from 'vue-infinite-scroll'
import Multiselect from 'vue-multiselect'

import AssetListItem from './AssetListItem'
import AssetTypeSelect from './AssetTypeSelect'
import BatteryIcon from './icons/BatteryIcon'
import ColorInput from './ColorInput'
import CursorMoveTargetRightIcon from './icons/CursorMoveTargetRightIcon'
import DisabledIcon from './icons/DisabledIcon'
import FilterIcon from './icons/FilterIcon'
import IconButton from './IconButton'
import List from './List'
import ListHeader from './ListHeader'
import LocationSelect from './LocationSelect'
import RecentIcon from '@/components/icons/RecentIcon'
import RemoveIcon from './icons/RemoveIcon'
import SearchField from './SearchField'
import SortIcon from './icons/SortIcon'
import WarningIcon from '@/components/icons/WarningIcon'
import MarkedIcon from '@/components/MarkedIcon'

const sortConst = {
    NameAcs: 1,
    NameDesc: 2,
    BatteryStatusAsc: 3,
    BatteryStatusDesc: 4,
    LastGPSAsc: 5,
    LastGPSDesc: 6,
}
const defaultSort = sortConst.NameAcs
const defaultScrollLimit = 15

export default {
    name: 'AssetList',
    components: {
        MarkedIcon,
        WarningIcon,
        AssetListItem,
        AssetTypeSelect,
        BatteryIcon,
        ColorInput,
        CursorMoveTargetRightIcon,
        DisabledIcon,
        FilterIcon,
        IconButton,
        List,
        ListHeader,
        LocationSelect,
        Multiselect,
        RecentIcon,
        RemoveIcon,
        SearchField,
        SortIcon,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        assets: {
            type: Array,
            required: true,
        },
        assetDetailLinkBase: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            filterByBattery: false,
            filterByColor: [],
            filterByInactive: false,
            filterByLeftZone: false,
            filterByLocation: [],
            filterByType: [],
            filterByAlert: false,
            filterByRecentlyActive: false,
            lastActiveFor: { duration: '6h', milliseconds: 21600000 },
            lastActiveOptions: [
                { duration: '1h', milliseconds: 3600000 },
                { duration: '6h', milliseconds: 21600000 },
                { duration: '12h', milliseconds: 43200000 },
                { duration: '1d', milliseconds: 86400000 },
                { duration: '1w', milliseconds: 604800000 },
                { duration: '1m', milliseconds: 2629746000 },
            ],
            listHeaderConsts: {
                none: 0,
                filter: 1,
                sort: 2,
            },
            listHeaderState: 0,
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            search: '',
            sortBy: defaultSort,
            sortData: [
                { text: this.$t('nameAZ'), value: sortConst.NameAcs },
                { text: this.$t('nameZA'), value: sortConst.NameDesc },
                {
                    text: this.$t('batteryEmptyFull'),
                    value: sortConst.BatteryStatusAsc,
                },
                {
                    text: this.$t('batteryFullEmpty'),
                    value: sortConst.BatteryStatusDesc,
                },
                {
                    text: this.$t('lastContactNewest'),
                    value: sortConst.LastMessageAsc,
                },
                {
                    text: this.$t('lastContactOldest'),
                    value: sortConst.LastMessageDesc,
                },
            ],
        }
    },
    computed: {
        ...mapState('tracker', [
            'filterParams',
            'shouldAdjustMapOnFilterChange',
        ]),
        isFiltered() {
            return (
                this.filterByBattery ||
                this.filterByInactive ||
                this.filterByLeftZone ||
                this.filterByAlert ||
                this.filterByRecentlyActive ||
                !!this.filterByType.length ||
                !!this.filterByLocation.length ||
                !!this.filterByColor.length
            )
        },
        filteredAssets() {
            let result = this.assets
            // Filter by search text box
            if (this.search !== '') {
                result = result.filter(tracker => {
                    return (
                        tracker.asset_details.name
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) != -1 ||
                        tracker.deveui
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) != -1 ||
                        (tracker.asset_details &&
                            tracker.asset_details.identification &&
                            tracker.asset_details.identification
                                .toLowerCase()
                                .indexOf(this.search.toLowerCase()) != -1)
                    )
                })
            }
            //Filter by filter box
            if (this.filterByBattery) {
                result = result.filter(
                    asset => asset.asset_details.sensor_data?.battery?.low
                )
            }
            if (this.filterByLeftZone) {
                result = result.filter(
                    asset => asset.last_gps_measurement?.is_outside
                )
            }
            if (this.filterByInactive) {
                result = result.filter(asset => !asset.active)
            }
            if (this.filterByAlert) {
                result = result.filter(asset => asset.asset_details.alert)
            }
            if (this.filterByRecentlyActive) {
                let now = new Date()
                result = result.filter(
                    tracker =>
                        now - new Date(tracker.last_message_timestamp) <
                        this.lastActiveFor.milliseconds
                )
            }
            if (this.filterByType.length) {
                result = result.filter(tracker =>
                    this.filterByType.includes(tracker.asset_details.asset_type)
                )
            }
            if (this.filterByLocation.length) {
                result = result.filter(tracker =>
                    this.filterByLocation.includes(tracker.location)
                )
            }
            if (this.filterByColor.length) {
                result = result.filter(tracker =>
                    this.filterByColor.includes(tracker.asset_details.color)
                )
            }
            //Sort with sort box
            if (this.sortBy != '') {
                switch (this.sortBy) {
                    case sortConst.NameAcs: {
                        result.sort((a, b) => {
                            const nameA = a.asset_details.name.toUpperCase()
                            const nameB = b.asset_details.name.toUpperCase()
                            if (nameA > nameB) return 1
                            if (nameA < nameB) return -1
                            return 0
                        })
                        break
                    }
                    case sortConst.NameDesc: {
                        result.sort((a, b) => {
                            const nameA = a.asset_details.name.toUpperCase()
                            const nameB = b.asset_details.name.toUpperCase()
                            if (nameA < nameB) return 1
                            if (nameA > nameB) return -1
                            return 0
                        })
                        break
                    }
                    case sortConst.BatteryStatusAsc: {
                        result.sort((a, b) => {
                            const valueA =
                                a.asset_details.sensor_data?.battery?.value
                            const valueB =
                                b.asset_details.sensor_data?.battery?.value
                            if (valueA == null || valueA > valueB) return 1
                            if (valueB == null || valueA < valueB) return -1
                            return 0
                        })
                        break
                    }
                    case sortConst.BatteryStatusDesc: {
                        result.sort((a, b) => {
                            const valueA =
                                a.asset_details.sensor_data?.battery?.value
                            const valueB =
                                b.asset_details.sensor_data?.battery?.value
                            if (valueA == null || valueA < valueB) return 1
                            if (valueB == null || valueA > valueB) return -1
                            return 0
                        })
                        break
                    }
                    case sortConst.LastGPSAsc: {
                        result.sort((a, b) => {
                            const timeA = a.last_message_timestamp
                            const timeB = b.last_message_timestamp
                            if (!timeA || timeA < timeB) return 1
                            if (!timeB || timeA > timeB) return -1
                            return 0
                        })
                        break
                    }
                    case sortConst.LastGPSDesc: {
                        result.sort((a, b) => {
                            const timeA = a.last_message_timestamp
                            const timeB = b.last_message_timestamp
                            if (!timeA || timeA > timeB) return 1
                            if (!timeB || timeA < timeB) return -1
                            return 0
                        })
                        break
                    }
                }
            }
            return result
        },
        assetsPortion() {
            return this.filteredAssets.length === this.assets.length
                ? this.filteredAssets.slice(0, this.scrollLimit)
                : this.filteredAssets
        },
    },
    watch: {
        filteredAssets(newFilteredAssets) {
            this.setFilteredTrackerIds(newFilteredAssets.map(item => item.id))
        },
        $route(to) {
            this.setFromRouteQuery(to.query)
        },
        assets(newAssets, oldAssets) {
            if (
                newAssets.length !== oldAssets.length ||
                newAssets.some(
                    (asset, index) => asset.id !== oldAssets[index].id
                )
            ) {
                this.resetFilter()
            }
        },
        filterByBattery(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
        filterByColor(newValue, oldValue) {
            if (
                newValue.length !== oldValue.length ||
                newValue.some((item, index) => item !== oldValue[index])
            ) {
                this.updateRoute()
            }
        },
        filterByInactive(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
        filterByAlert(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
        filterByRecentlyActive(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
        filterByLeftZone(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
        filterByLocation(newValue, oldValue) {
            if (
                newValue.length !== oldValue.length ||
                newValue.some((item, index) => item !== oldValue[index])
            ) {
                this.updateRoute()
            }
        },
        filterByType(newValue, oldValue) {
            if (
                newValue.length !== oldValue.length ||
                newValue.some((item, index) => item !== oldValue[index])
            ) {
                this.updateRoute()
            }
        },
        search(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
        sortBy(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateRoute()
            }
        },
    },
    mounted() {
        if (this.filterParams) {
            this.$router.replace({ query: this.filterParams })
        } else {
            this.setFromRouteQuery(this.$route.query)
        }
    },
    methods: {
        ...mapMutations('tracker', [
            'setFilteredTrackerIds',
            'setFilterParams',
            'setShouldAdjustMapOnFilterChange',
        ]),
        setFromRouteQuery(query) {
            this.filterByBattery = !!query.lowBattery
            this.filterByColor = query.color ? [].concat(query.color) : []
            this.filterByInactive = !!query.inactive
            this.filterByAlert = !!query.alert
            this.filterByRecentlyActive = !!query.recent
            this.filterByLeftZone = !!query.leftZone
            this.filterByLocation = query.location
                ? [].concat(query.location).map(item => +item)
                : []
            this.filterByType = query.type
                ? [].concat(query.type).map(item => +item)
                : []
            this.sortBy = +query.sort || this.sortBy
            this.search = query.search || ''
        },
        updateRoute: debounce(function() {
            let query = {}

            if (this.filterByBattery) {
                query.lowBattery = this.filterByBattery
            }
            if (this.filterByColor.length) {
                query.color = this.filterByColor
            }
            if (this.filterByInactive) {
                query.inactive = this.filterByInactive
            }
            if (this.filterByAlert) {
                query.alert = this.filterByAlert
            }
            if (this.filterByRecentlyActive) {
                query.recent = this.filterByRecentlyActive
            }
            if (this.filterByLeftZone) {
                query.leftZone = this.filterByLeftZone
            }
            if (this.filterByLocation.length) {
                query.location = this.filterByLocation
            }
            if (this.filterByType.length) {
                query.type = this.filterByType
            }
            if (this.search) {
                query.search = this.search
            }
            if (this.sortBy) {
                query.sort = this.sortBy
            }

            this.setFilterParams(query)
            this.$router.replace({ query })
        }, 500),
        resetFilter() {
            this.filterByBattery = false
            this.filterByColor = []
            this.filterByInactive = false
            this.filterByAlert = false
            this.filterByLeftZone = false
            this.filterByRecentlyActive = false
            this.filterByLocation = []
            this.filterByType = []
            this.search = ''
            this.sortBy = defaultSort
        },
        loadMoreAssets() {
            if (this.scrollLimit < this.assets.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        switchAdjustingMode() {
            this.setShouldAdjustMapOnFilterChange(
                !this.shouldAdjustMapOnFilterChange
            )

            if (this.shouldAdjustMapOnFilterChange) {
                let filter = this.filterParams || {}
                delete filter.sortBy
                if (Object.keys(filter).length) {
                    this.setFilterParams({ ...this.filterParams })
                }
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "1h": "in the last hour",
        "6h": "in the last 6 hours",
        "12h": "in the last 12 hours",
        "1d": "in the last day",
        "1w": "in the last week",
        "1m": "in the last month",
        "adjustMapOnFilterChange": "Center map on filter results",
        "batteryEmptyFull": "Battery level (empty → full)",
        "batteryFullEmpty": "Battery level (full → empty)",
        "filterBy": "Filter",
        "inactive": "Inactive",
        "lastContactNewest": "Last contact (recent → old)",
        "lastContactOldest": "Last contact (old → recent)",
        "leftZone": "Left zone",
        "listedOf": "Show {0} of {1}",
        "location": "Location",
        "lowBattery": "Low battery",
        "nameAZ": "Name (A → Z)",
        "nameZA": "Name (Z → A)",
        "recentlyActive": "Active",
        "sortedBy": "Sorted by ",
        "type": "Type",
        "hasAlert": "Has alert"
    },
    "de": {
        "1h": "in der vergangenen Stunde",
        "6h": "in den letzten 6 Stunden",
        "12h": "in den letzten 12 Stunden",
        "1d": "im vergangenen Tag",
        "1w": "in der vergangenen Woche",
        "1m": "im vergangenen Monat",
        "adjustMapOnFilterChange": "Karte auf die Filterergebnisse zentrieren",
        "batteryEmptyFull": "Batteriestatus (Leer → Voll)",
        "batteryFullEmpty": "Batteriestatus (Voll → Leer)",
        "filterBy": "Filter",
        "inactive": "Inaktiv",
        "lastContactNewest": "Letzter Kontakt (Neu → Alt)",
        "lastContactOldest": "Letzter Kontakt (Alt → Neu)",
        "leftZone": "Zone Verlassen",
        "listedOf": "Zeige {0} von {1}",
        "location": "Standort",
        "lowBattery": "Niedriger Batteriestand",
        "nameAZ": "Name (A → Z)",
        "nameZA": "Name (Z → A)",
        "recentlyActive": "Aktiv",
        "sortedBy": "Sortiert nach ",
        "type": "Typ",
        "hasAlert": "Hat Alarm"
    },
    "it": {
        "1h": "nell'ultima ora",
        "6h": "nelle ultime 6 ore",
        "12h": "nelle ultime 12 ore",
        "1d": "nell'ultimo giorno",
        "1w": "nell'ultima settimana",
        "1m": "nell'ultimo mese",
        "adjustMapOnFilterChange": "Centrare la mappa sui risultati del filtro",
        "batteryEmptyFull": "Livello Batteria (Vuota → Piena)",
        "batteryFullEmpty": "Livello Batteria (Piena → Vuota)",
        "filterBy": "Filtro",
        "inactive": "Inattivo",
        "lastContactNewest": "Ultima Contatto (Recente → Vecchia)",
        "lastContactOldest": "Ultima Contatto (Vecchia → Recente)",
        "leftZone": "Escono Zona",
        "listedOf": "Listo {0} di {1}",
        "location": "Locazione",
        "lowBattery": "Poca Batteria",
        "nameAZ": "Nome (A → Z)",
        "nameZA": "Nome (Z → A)",
        "recentlyActive": "Attivo",
        "sortedBy": "Diviso tra ",
        "type": "Typo",
        "hasAlert": "Cappello Allarme"
    }
}
</i18n>

<style scoped lang="scss">
.adjusting-mode-switch {
    margin-top: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
}

.multiselect {
    width: fit-content;
}

.sort-panel {
    background: $color-gray-lighter;

    .split {
        padding: 0.5rem 0;
    }
}

.sort-option {
    cursor: pointer;
}

.select-li {
    color: darkgray;
}

.dropdown {
    &-enter,
    &-leave-to {
        max-height: 0;
        opacity: 0;
    }

    &-leave,
    &-enter-to {
        max-height: 400px;
        opacity: 1;
    }

    &-enter-active,
    &-leave-active {
        transition: max-height 0.2s linear, opacity 0.4s ease-in-out;
    }
}
</style>
